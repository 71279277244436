body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1e;
  color: white;
}

.app {
  text-align: center;

  position: relative;
  display: flex; 
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  height: 100vh;
  overflow: hidden;
}

.slide {
  padding: 40px;
  width: 100%;
  height: 70%;
  background-color: #2c2c2e;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 24px;
  position: relative; /* This ensures correct stacking context for absolute children */
  z-index: 1;
  overflow-y: auto;
  
}
.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none; /* This allows clicks to pass through to content below */
}

.controls button {
  background-color: #333;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.controls button:hover,
.controls button:disabled {
  opacity: 1;
}
.controls button {
  pointer-events: auto; /* Re-enable pointer events for buttons */
  position: absolute;
  background-color: #333;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.controls button:hover,
.controls button:disabled {
  opacity: 1;
}

.controls button:first-of-type {
  left: 10px; /* Position the "previous" button on the left */
}

.controls button:last-of-type {
  right: 10px; /* Position the "next" button on the right */
}


.control {
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 2; /* Ensure the buttons are above the slide content */
}

.control:hover,
.control:disabled {
  opacity: 1;
}

.control.left {
  left: 10px;
}

.control.right {
  right: 10px;
}

.control:disabled {
  opacity: 0.3; /* Dim the button to indicate it's disabled */
  cursor: default; /* Change the cursor to the default to indicate it's not clickable */
}
.slide-image {
  max-width: 40%; /* Ensure the image is not wider than the slide */
  max-height: auto; /* Maintain the aspect ratio of the image */
  margin-bottom: 0px;
  border-radius: 10px;
  
 /* Space between the image and the text content */
}
.mobile-message {
  display: none;
  position: fixed;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  text-align: center;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  padding: 20px;
  margin-right: 20;
  font-size: 1rem;
  z-index: 1000; /* Make sure it's above all other content */
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your target mobile devices */
  .mobile-message {
    display: block;
  }
}